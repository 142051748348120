import M from 'materialize-css'
import destroyMaterializeInstances from 'destroy_materialize_instances'
import '../javascript/page_specific/manifest'

document.addEventListener('turbolinks:load', () => {
  M.AutoInit()
  M.updateTextFields()
})

document.addEventListener('turbolinks:before-cache', () => {
  destroyMaterializeInstances()
})
