import M from 'materialize-css'

export default function destroyMaterializeInstances() {
  document.querySelectorAll('.collapsible').forEach(e =>
    M.Collapsible.getInstance(e).destroy())
  document.querySelectorAll('.dropdown-trigger').forEach(e =>
    M.Dropdown.getInstance(e).destroy())
  document.querySelectorAll('.modal').forEach(e =>
    M.Modal.getInstance(e).destroy())
  document.querySelectorAll('.pushpin').forEach(e =>
    M.Pushpin.getInstance(e).destroy())
  document.querySelectorAll('.sidenav').forEach(e =>
    M.Sidenav.getInstance(e).destroy())
  document.querySelectorAll('.tabs').forEach(e =>
    M.Tabs.getInstance(e).destroy())
  document.querySelectorAll('.tooltip').forEach(e =>
    M.Tooltip.getInstance(e).destroy())
}
