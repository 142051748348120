class QueryError extends Error {
  constructor(message?: string, items?: Array<string>) {
    super(message)
    this.name = 'QueryError'
    if (items) {
      this.message += '\n' + items.join('\n')
    }
  }
}

function typedQuery<T extends Element>(query: string, type?: new() => T) {
  const result = document.querySelector<T>(query)
  if (result === null) {
    throw new QueryError('No element found for query: ' + query)
  }
  if (type && !(result instanceof type)) {
    const actual = (result.constructor as any).name
    const expected = (type as any).name
    throw new QueryError(`'${actual}' found instead of '${expected}' for query '${query}'`)
  }
  return result
}

function typedQueryAll<T extends Element>(query: string, type?: new() => T) {
  const queryResult = document.querySelectorAll<T>(query)
  const result = new Array<T>(queryResult.length)
  if (type) {
    const errors = new Array<string>()
    for (let i = 0; i < queryResult.length; i++) {
      const element = queryResult.item(i)
      if (!(element instanceof type)) {
        const actual = (element.constructor as any).name
        errors.push(`'${actual}' found at index ${i}`)
      }
      result[i] = element
    }
    if (errors.length > 0) {
      const expected = (type as any).name
      throw new QueryError(`'${expected}' elements expected for queryAll '${query}'`, errors)
    }
  } else {
    for (let i = 0; i < queryResult.length; i++) {
      result[i] = queryResult.item(i)
    }
  }
  return result
}

export { typedQuery, typedQueryAll, QueryError }
