function onPage(pages: string | string[], callback: () => void) {
  document.addEventListener('turbolinks:load', () => {
    if (typeof pages === 'string') {
      pages = [pages]
    }
    if (pages.some(page => document.body.classList.contains(page))) {
      callback()
    }
  })
}

export default onPage
