import Plotly from 'plotly.js-basic-dist'
import M from 'materialize-css'
import { typedQuery, typedQueryAll } from '../typed_query'
import onPage from './on_page'

type Note = [string, string]

onPage('devices_show', () => {
  const graphElement = typedQuery('#device-graph', HTMLElement)
  const serialNumber = graphElement.dataset.serial
  const modalElement = typedQuery('#note-modal', HTMLElement)
  const noteModal = M.Modal.init(modalElement)
  const noteForm = typedQuery('#new_note', HTMLFormElement)
  const downloadDataButton = typedQuery('#download-data', HTMLAnchorElement)
  const baseUrl = `/data/${serialNumber}`

  function fetchAndRender(from = '', to = '') {
    const params = `?from=${from}&to=${to}`
    downloadDataButton.href = baseUrl + '.xlsx' + params

    fetch(baseUrl + params)
      .then(response => response.json())
      .then(graphData => {
        Plotly.newPlot('device-graph', graphData.data, createLayout(graphData.notes))
        populateNotesTable(graphData.notes)
        setupGraphClickListener(graphElement as Plotly.PlotlyHTMLElement, noteModal)
      })
  }  

  noteForm.addEventListener('submit', () => noteModal.close())

  noteForm.addEventListener('ajax:success', () => {
    M.toast({ html: 'Poznámka byla přidána' })
    fetchAndRender()
  })
  
  noteForm.addEventListener('ajax:error', (event: any) => {
    const errors = event.detail[0]
    errors.forEach((error: string) => M.toast({ html: error }))
  })

  typedQueryAll('.graph-reload', HTMLElement).forEach(btn => {
    btn.addEventListener('click', () => {
      fetchAndRender(btn.dataset.from || '', btn.dataset.to || '')
    })
  })

  initializeDateRange()

  fetchAndRender()
})

function populateNotesTable(notes: Note[]) {
  const notesTableBody = typedQuery('#notes-table-body', HTMLElement)
  notesTableBody.innerHTML = notes.sort(compareTimestamps).map(note => 
    `<tr>
      <td>${formatDate(new Date(note[0]))}</td>
      <td>${note[1]}</td>
    </tr>`
  ).join('')
}

function compareTimestamps(a: Note, b: Note) {
  const d1 = new Date(a[0]).getTime()
  const d2 = new Date(b[0]).getTime()
  return d2 - d1
}

function setupGraphClickListener(element: Plotly.PlotlyHTMLElement, noteModal: M.Modal) {
  const titleTimestamp = typedQuery('#title-timestamp', HTMLElement)
  const timestampField = typedQuery('#note_timestamp', HTMLInputElement)
  const contentField = typedQuery('#note_content', HTMLInputElement)

  element.on('plotly_click', (data) => {
    const timestamp = String(data.points[0].x)
    titleTimestamp.innerHTML =  formatDate(new Date(timestamp))
    timestampField.value = timestamp
    contentField.value = ''
    noteModal.open()
    contentField.focus()
  })
}

function formatDate(date: Date) {
  return `${padTime(date.getDate())}. ${padTime(date.getMonth() + 1)}. ${date.getFullYear()} ${padTime(date.getHours())}:${padTime(date.getMinutes())}`
}

function padTime(time: number) {
  return time.toString().padStart(2, '0')
}

function createLayout(notes: Note[]): Partial<Plotly.Layout> {
  return {
    annotations: notes.map(createAnnotation)
  }
}

function createAnnotation(note: Note): Partial<Plotly.Annotations> {
  return {
    x: note[0],
    y: 0,
    arrowhead: 6,
    ax: 0,
    ay: 20,
    text: '?',
    font: {
      size: 16
    },
    hovertext: note[1]
  }
}

function initializeDateRange() {
  const showRangeButton = typedQuery('#show-range', HTMLElement)
  const rangeFromInput = typedQuery('#range_from')
  const rangeToInput = typedQuery('#range_to')

  M.Datepicker.init(rangeFromInput, Object.assign(defaultDatepickerOptions(), {
    onSelect: (date: Date) => showRangeButton.dataset.from = formatDate(date)
  }))

  M.Datepicker.init(rangeToInput, Object.assign(defaultDatepickerOptions(), {
    onSelect: (date: Date) => showRangeButton.dataset.to = formatDate(date)
  }))
}

function defaultDatepickerOptions(): Partial<M.DatepickerOptions> {
  return {
    autoClose: true,
    format: 'dd. mm. yyyy',
    i18n: {
      months: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'],
      monthsShort: ['led', 'úno', 'bře', 'dub', 'kvě', 'čer', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro'],
      weekdays: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
      weekdaysShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
      weekdaysAbbrev: ['P', 'Ú', 'S', 'Č', 'P', 'S', 'N'],
      cancel: 'Zavřít',
      clear: 'Vymazat',
      done: 'Potvrdit'
    }
  }
}
